import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import Link from 'react-storefront/link/Link';
import theme from '../theme';
import { TrackingContext } from '../providers/tracking';

const styles = {
	logo__wrapper: {
		display: 'flex',
		justifyContent: 'center',
		gridRow: '1 / 1',
    	gridColumn: '2 / 3',
		[theme.breakpoints.up('sm')]: {
			padding: '0px 15px'
		}
	},
	logo__image: {
		display: 'block',
		width: 100,
		height: 33,
		[theme.breakpoints.up('sm')]: {
			width: 160,
			height: 53
		}
	}
};

const useStyles = makeStyles(() => styles);

export default function Logo() {
	const classes = useStyles();
	const { actions: trackingActions } = useContext(TrackingContext);

	return (
		<div className={classes.logo__wrapper}>
			<Link as="/" href="/">
				<a onClick={() => trackingActions.click({ type: 'header_logo', text: 'home', identifier: '/' })}>
					<img
						alt={theme.logo.alt}
						src={theme.logo.url}
						className={classes.logo__image}
						width={styles.logo__image.width}
						height={styles.logo__image.height}
					/>
				</a>
			</Link>
		</div>
	);
}
